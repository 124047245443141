import Win1Token from "../../img/1win_coin.png"
import Button from "../Modules/Button/Button"
import request from '../../utils/request'

export default function BannerBottom() {
    const log = () => {
        request('stats', "POST", { type: '1win coin' })
    }

    return <div className="BannerBottom">
        <div className="Reward1WinToken">
            <div className="Reward1WinTokenInfo">
                <h1>1win Token
                    <br></br>Зарабатывайте токены до листинга на бирже!
                </h1>

                <Button 
                    style={{ marginTop: '10px'}} 
                    href="http://t.me/token1win_bot/start?startapp=refId448148292"
                    target="_blank"
                    onClick={log}
                >
                    Играть
                </Button>
            </div>
            <img src={Win1Token} alt="promo"/>
        </div>
    </div>
}